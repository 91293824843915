import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Bio from "../components/bio"
import Layout from "../components/layout"

import SEO from "../components/seo"

function embeddedAsset(node) {
  return (
    <img
      style={{
        maxWidth: "100%",
      }}
      src={node?.data?.target?.fields?.file["nb"]?.url}
      alt=""
    />
  )
}

const BlogPostContentfulTemplate = ({ data, pageContext, location }) => {
  const post = data?.contentfulPost
  if (!post) return null
  const { previous, next } = pageContext
  const json = post?.content?.content
  const docContent = json ? JSON.parse(json) : null
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => embeddedAsset(node),
    },
  }
  const postDate = new Date(post.date)
  return (
    <Layout location={location} title={"← Michael Olsen's Blog"} fromBlog>
      <SEO title={post.title} description={post.subtitle} />
      <article itemScope itemType="http://schema.org/Article">
        <Img
          fluid={post.image?.fluid}
          style={{
            maxWidth: "100%",
            borderRadius: "10px",
          }}
          alt=""
        />
        <header>
          <h1
            itemProp="headline"
            style={{
              marginTop: "50px",
              marginBottom: "50px",
              fontSize: "40px",
            }}
          >
            {post.title}
          </h1>
        </header>
        <span>{`${postDate.getDate()}/${postDate.getMonth() < 9 ? "0" : ""}${
          postDate.getMonth() + 1
        }/${postDate.getFullYear()}`}</span>
        {documentToReactComponents(docContent, options)}
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/blog/${previous.slug}`} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/blog/${next.slug}`} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

BlogPostContentfulTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query BlogPostContentfulBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      title
      subtitle
      author
      date
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      content {
        content
      }
    }
  }
`
